/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { change } from 'redux-form';
import PropTypes from 'prop-types';

import {
  clearNavigationInterrupt,
  registerNavigationInterrupt,
} from '../../actions';
import { ARGYLE_DEV_TOOLS_ENABLED } from '../../constants';
import { EMPLOYMENT_INPUT_MODE_MANUAL } from '../../lib/employment/constants';
import {
  argyleErrorLog,
  getEmploymentKeyPart,
  hasAllJobsOutOfDateRange,
} from '../../lib/employment/utils/employmentUtils';
import { AddFieldButton } from '../fields';
import ArgyleDevToolsMenu from './dev-tools/ArgyleDevToolsMenu';
import Gap from './Gap';
import Employer from './Employer';

class EmploymentHelper {
  components = {
    gap: Gap,
    employer: Employer,
  };

  gapCounter = 0;

  employerCounter = 0;

  configFor(type) {
    const componentType = type || 'employer';
    return {
      EmploymentForm: this.components[componentType],
      nth: this.incrementBy(componentType),
    };
  }

  incrementBy(componentType) {
    const value = this[`${componentType}Counter`];
    this[`${componentType}Counter`] += 1;
    return value;
  }
}

class Employers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    employers: PropTypes.arrayOf(PropTypes.object),
    fieldLength: PropTypes.number,
    handleOnRemove: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    managerRequired: PropTypes.bool,
    contractTypeRequired: PropTypes.bool,
    maximumAllowedEmployers: PropTypes.number,
    lookbackYears: PropTypes.number.isRequired,
    showNoHistoryOption: PropTypes.bool,
    fullLookbackRequired: PropTypes.bool,
    isDot: PropTypes.bool,
    documentCollection: PropTypes.bool,
    argyleEnabled: PropTypes.bool,
    argyleAccountEmployments: PropTypes.arrayOf(
      PropTypes.shape({
        argyleAccountId: PropTypes.string,
        argyleEmployments: PropTypes.arrayOf(PropTypes.object),
      }),
    ),
    openArgyle: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.checkAllHistoriesHaveInvalidDateRange = this.checkAllHistoriesHaveInvalidDateRange.bind(
      this,
    );
  }

  // eslint-disable-next-line camelcase
  componentDidMount() {
    const {
      fields,
      dispatch,
      lookbackYears,
      showNoHistoryOption,
      fullLookbackRequired,
    } = this.props;

    // Avoid navigation interrupt when full lookback is required as the history
    // gap is addressed in the ConfirmFullLookbackCheckbox
    if (!showNoHistoryOption && !fullLookbackRequired) {
      dispatch(
        registerNavigationInterrupt(this.checkAllHistoriesHaveInvalidDateRange),
      );
    }

    if (!fields.length) {
      this.addEmployer();
    }

    // save this in the form for use when submitting form (for analytics purposes)
    dispatch(change('applyform', 'lookbackYears', lookbackYears));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearNavigationInterrupt());
  }

  addEmployer = () => {
    const { fields, argyleEnabled } = this.props;
    fields.push({
      type: 'employer',
      inputMode: argyleEnabled ? null : EMPLOYMENT_INPUT_MODE_MANUAL,
      addedAt: Date.now(),
    });
  };

  addGap = () => {
    const { fields } = this.props;
    fields.push({ type: 'gap', addedAt: Date.now() });
  };

  handleOnEmployerOrGapRemove = index => {
    const { handleOnRemove, fields } = this.props;
    const removingLastRemaining = fields.length === 1;

    // Notify parent DecoratedFieldArray to remove this element from fields.
    handleOnRemove(index);

    // Removing the last remaining element is allowed when it's Argyle.
    // Re-add a brand new one to basically reset it.
    if (removingLastRemaining) {
      this.addEmployer();
    }
  };

  getArgyleEmployment = argyleEmploymentId => {
    const { argyleAccountEmployments } = this.props;

    const argyleEmployments = argyleAccountEmployments
      .map(account => account.argyleEmployments)
      .flat();

    const argyleEmployment = argyleEmployments.find(
      argyleEmp => argyleEmp.argyleEmploymentId === argyleEmploymentId,
    );

    if (argyleEmployment == null) {
      argyleErrorLog(
        `${`Unable to find argyleEmployment ${argyleEmploymentId} in Redux state ` +
          `which has ${argyleEmployments.length} argyleEmployments: `}${argyleEmployments
          .map(emp => emp.argyleEmploymentId)
          .join(', ')}`,
        { captureSentryException: true },
      );
    }

    return argyleEmployment;
  };

  checkAllHistoriesHaveInvalidDateRange() {
    const { lookbackYears, employers } = this.props;
    const jobs = employers.filter(employer => employer.type === 'employer');
    return hasAllJobsOutOfDateRange(lookbackYears, jobs);
  }

  showAddEmployerButton(count) {
    const { maximumAllowedEmployers } = this.props;
    if (maximumAllowedEmployers && count >= maximumAllowedEmployers) {
      return false;
    }
    return true;
  }

  render() {
    const {
      dispatch,
      employers,
      fields,
      fieldLength,
      managerRequired,
      contractTypeRequired,
      lookbackYears,
      isDot,
      documentCollection,
      argyleEnabled,
      openArgyle,
    } = this.props;
    const employmentHelper = new EmploymentHelper();

    return (
      <div>
        {fields.map((fieldName, i) => {
          const employer = employers[i];
          const { type, argyleEmploymentId } = employer;
          const { EmploymentForm, nth } = employmentHelper.configFor(type);

          const argyleEmployment = argyleEmploymentId
            ? this.getArgyleEmployment(argyleEmploymentId) || {}
            : null;

          const keyPart = getEmploymentKeyPart(employer);

          return (
            <EmploymentForm
              key={`employer-${keyPart}`}
              {...{
                dispatch,
                fieldName,
                employer,
                handleOnRemove: index =>
                  this.handleOnEmployerOrGapRemove(index),
                fieldLength,
                i,
                nth,
                managerRequired,
                contractTypeRequired,
                lookbackYears,
                isDot,
                documentCollection,
                argyleEnabled,
                argyleEmployment,
                openArgyle,
              }}
            />
          );
        })}

        {this.showAddEmployerButton(employmentHelper.employerCounter) && (
          <AddFieldButton
            handleOnClick={() => this.addEmployer()}
            buttonText={`components.Employers.Add_${
              employmentHelper.employerCounter < 10 &&
              employmentHelper.employerCounter > 0
                ? employmentHelper.employerCounter
                : 'other'
            }`}
          />
        )}
        <AddFieldButton
          handleOnClick={() => this.addGap()}
          buttonText={`components.EmploymentGap.Add_${
            employmentHelper.gapCounter < 10
              ? employmentHelper.gapCounter
              : 'other'
          }`}
        />

        {argyleEnabled && ARGYLE_DEV_TOOLS_ENABLED && <ArgyleDevToolsMenu />}
      </div>
    );
  }
}

export default Employers;
