/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { Checkbox, Select, Text, TypeaheadInput, Upload } from '../fields';
import requestEmployers from '../../lib/employment/utils/employerUtils';
import { US_COUNTRY_VALUE } from '../../constants';
import * as O from '../../lib/options';
import * as V from '../../lib/validations';
import setDocumentContextAttributes from '../../lib/utils/documentUtils';
import EmploymentPeriod from './EmploymentPeriod';
import { SELF_EMPLOYED } from '../../lib/options/contracts';
import DomesticDocumentUpload from '../DomesticDocumentUpload';
import HTMLText from '../HTMLText';

/**
 * Update this version date any time components.domesticDocumentUpload.candidateDisclosurePrompt.employment
 * is updated
 */
const CANDIDATE_EMPLOYMENT_DOCUMENT_DISCLOSURE_VERSION = '2023-10-03';
const EMPLOYER_NAME_AUTOCOMPLETE_MINLENGTH = 3;
const EMPLOYER_NAME_AUTOCOMPLETE_MAXRESULTS = 20;
const handleDisclosableDocuments = (_, documents) => {
  setDocumentContextAttributes(documents, {
    candidateDisclosureVersion: CANDIDATE_EMPLOYMENT_DOCUMENT_DISCLOSURE_VERSION,
  });
};

const generateText = (keyItem, contentPrefix) => {
  return (
    <HTMLText
      className='option'
      key={`item-${keyItem}`}
      content={i18n.getStr(`${contentPrefix}.${keyItem}`)}
    />
  );
};

const getEmploymentBodyText = () => {
  const keys = ['w2Or1099', 'paystub', 'scheduleC'];
  const valuePrefix =
    'components.domesticDocumentUpload.documentTypeDetailsModal.employment';
  return keys.map((key, index) => generateText(key, valuePrefix));
};

const getTranslatedTextForDocumentComponent = () => {
  return {
    candidateDisclosureText: i18n.getStr(
      `components.domesticDocumentUpload.candidateDisclosurePrompt.employment`,
    ),
    descriptionText: i18n.getStr(
      `components.domesticDocumentUpload.descriptionText.employment`,
    ),
    modalBodyContent: getEmploymentBodyText(),
    titleText: i18n.getStr(
      `components.domesticDocumentUpload.sectionTitle.employment`,
    ),
  };
};

const Manager = ({ fieldName }) => (
  <div>
    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='text'
          name={`${fieldName}.manager.name`}
          label='labels.managerName'
          component={Text}
          validate={[V.required, value => V.maxLengthFormat(value, 255)]}
        />
      </div>

      <div className='col-md-6'>
        <Field
          type='text'
          name={`${fieldName}.manager.title`}
          label='labels.managerTitle'
          component={Text}
          validate={[V.required, value => V.maxLengthFormat(value, 255)]}
        />
      </div>
    </div>

    <div className='row'>
      <div className='col-md-6'>
        <Field
          type='email'
          name={`${fieldName}.manager.email`}
          label='labels.managerEmail'
          component={Text}
          validate={[V.emailFormat, value => V.maxLengthFormat(value, 255)]}
        />
      </div>

      <div className='col-md-6'>
        <Field
          type='tel'
          name={`${fieldName}.manager.phone`}
          label='labels.managerPhone'
          component={Text}
          validate={[value => V.maxLengthFormat(value, 255)]}
        />
      </div>
    </div>
  </div>
);

Manager.propTypes = {
  fieldName: PropTypes.string,
};

class ManualEmployer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    employer: PropTypes.object.isRequired,
    managerRequired: PropTypes.bool,
    contractTypeRequired: PropTypes.bool,
    lookbackYears: PropTypes.number.isRequired,
    isDot: PropTypes.bool,
    documentCollection: PropTypes.bool,
  };

  /**
   * @name shouldShowManagerContactFields
   * @function
   * @memberOf ManualEmployer
   * @description Prevent the manager contact fields from showing
   *              if the contractType is self employed or
   *              do not contact is checked
   * @returns {boolean}
   */
  shouldShowManagerContactFields = () => {
    const { managerRequired, employer } = this.props;
    return (
      (managerRequired &&
        !employer.doNotContact &&
        employer.contractType !== SELF_EMPLOYED) ||
      (employer.country && employer.country !== US_COUNTRY_VALUE)
    );
  };

  /**
   * @name shouldShowDoNotContact
   * @function
   * @memberOf ManualEmployer
   * @description Returns whether the "Don't contact this employer" checkbox should appear.
   * @returns {boolean}
   */
  shouldShowDoNotContact = () => {
    const { employer, isDot } = this.props;
    return (
      employer.currentPosition &&
      employer.contractType !== SELF_EMPLOYED &&
      !isDot
    );
  };

  render() {
    const {
      contractTypeRequired,
      dispatch,
      documentCollection,
      employer,
      employer: { country, contractType },
      fieldName,
      lookbackYears,
      managerRequired,
    } = this.props;
    const isInternational = country !== US_COUNTRY_VALUE;
    const {
      candidateDisclosureText,
      descriptionText,
      modalBodyContent,
      titleText,
    } = getTranslatedTextForDocumentComponent();
    return (
      <div id='manualEmploymentForm'>
        <div className='employment-details'>
          {contractTypeRequired && (
            // only display contractType row if it's required
            <div className='row employment-contract-details'>
              <div className='col-md-6'>
                <Field
                  type='select'
                  name={`${fieldName}.contractType`}
                  label='labels.contractType'
                  component={Select}
                  validate={[V.required]}
                  options={O.contracts}
                />
              </div>
            </div>
          )}
          <div className='row employer-name-details'>
            <div className='col-md-6'>
              {!isInternational && (
                <Field
                  type='text'
                  name={`${fieldName}.name`}
                  label='labels.employerName'
                  placeholder='placeholders.employerName'
                  minLength={EMPLOYER_NAME_AUTOCOMPLETE_MINLENGTH}
                  maxResults={EMPLOYER_NAME_AUTOCOMPLETE_MAXRESULTS}
                  component={TypeaheadInput}
                  onSearch={requestEmployers}
                  validate={[
                    V.required,
                    value => V.maxLengthFormat(value, 255),
                  ]}
                  suggest
                  typeaheadAsync
                />
              )}
              {isInternational && (
                <Field
                  type='text'
                  name={`${fieldName}.name`}
                  label='labels.employerName'
                  component={Text}
                  validate={[V.required]}
                />
              )}
            </div>

            <div className='col-md-6'>
              <Field
                type='text'
                name={`${fieldName}.position`}
                label='labels.position'
                component={Text}
                validate={[V.required, value => V.maxLengthFormat(value, 255)]}
              />
            </div>
          </div>

          <div className='row employment-location-details'>
            <div className='col-md-6'>
              <Field
                type='text'
                name={`${fieldName}.city`}
                label='labels.city'
                component={Text}
                validate={[V.required]}
              />
            </div>

            <div className='col-md-6'>
              {!isInternational && (
                <Field
                  type='select'
                  name={`${fieldName}.state`}
                  label='labels.state'
                  component={Select}
                  validate={[V.required]}
                  options={O.states()}
                />
              )}
              {isInternational && (
                <Field
                  type='text'
                  name={`${fieldName}.state`}
                  label='international.labels.schoolState'
                  component={Text}
                  validate={[V.required]}
                />
              )}
            </div>
          </div>

          <div className='row salary-url-details'>
            {isInternational && (
              <div className='col-md-6'>
                <Field
                  type='text'
                  name={`${fieldName}.url`}
                  label='labels.employerUrlOptional'
                  component={Text}
                />
              </div>
            )}
          </div>

          {isInternational && (
            <div className='row international-details'>
              <div className='col-md-6'>
                <Field
                  type='text'
                  name={`${fieldName}.address`}
                  label='labels.address'
                  component={Text}
                  validate={[V.required]}
                />
              </div>

              <div className='col-md-6'>
                <Field
                  type='text'
                  name={`${fieldName}.zipcode`}
                  label='labels.postalCode'
                  component={Text}
                  validate={[V.required]}
                />
              </div>
            </div>
          )}

          <EmploymentPeriod
            {...{
              dispatch,
              employer,
              fieldName,
              lookbackYears,
            }}
            presentLabel='labels.currentPosition'
          />
        </div>
        <div className='mgr-section'>
          {managerRequired && contractType !== SELF_EMPLOYED && (
            <div className='row'>
              <div className='col-md-12'>
                <p>
                  <strong>
                    <T value='components.Employer.contactInstructions' />
                  </strong>
                </p>
              </div>
            </div>
          )}

          {this.shouldShowDoNotContact() && (
            <div className='row'>
              <div className='col-md-12'>
                <div className='alert alert-secondary' role='alert'>
                  <Field
                    type='checkbox'
                    name={`${fieldName}.doNotContact`}
                    className='no-bottom-margin'
                    label='labels.employerDoNotContactHelpText'
                    component={Checkbox}
                    boldLabel
                  />
                </div>
              </div>
            </div>
          )}

          {this.shouldShowManagerContactFields() && (
            <Manager {...{ fieldName }} />
          )}
        </div>

        {documentCollection &&
          (isInternational ? (
            // intl doc upload
            <>
              <div className='row'>
                <div className='col-md-12'>
                  <strong>
                    <T value='components.domesticDocumentUpload.candidateDisclosurePrompt.employment' />
                  </strong>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                  <Field
                    type='upload'
                    name={`${fieldName}.documents`}
                    component={Upload}
                    onChange={handleDisclosableDocuments}
                    options={{ maxFiles: 1 }}
                    boldLabel
                  />
                </div>
              </div>
            </>
          ) : (
            <DomesticDocumentUpload
              {...{
                candidateDisclosureText,
                descriptionText,
                dispatch,
                dropdownOptions: O.employmentDocuments,
                fieldName,
                handleDisclosableDocuments,
                modalBodyContent,
                titleText,
              }}
            />
          ))}
      </div>
    );
  }
}

export default ManualEmployer;
