import React from 'react';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { Field, reduxForm } from 'redux-form';
import Panel from '../Panel';
import { Text } from '../fields';
import ControlFormImage from './assets/custody_control_form.png';
import '../../css/CustodyControlForm.scss';
import * as N from '../../lib/normalizations';
import * as V from '../../lib/validations';

const CustodyControlForm = () => (
  <Panel title='components.CustodyControlForm.title'>
    <p>
      <T value='components.CustodyControlForm.body' dangerousHTML />
    </p>
    <img
      src={ControlFormImage}
      className='control-form-img'
      alt='Custody Control Form'
    />
    <Field
      type='text'
      name='custodyControlFormNumber'
      label='components.CustodyControlForm.label'
      placeholder='components.CustodyControlForm.placeholder'
      validate={[
        V.custodyControlNumberFormat,
        value => V.maxLengthFormat(value, 50),
      ]}
      normalize={N.smartPunctuation}
      component={Text}
    />
  </Panel>
);

export default reduxForm({
  form: 'applyform',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(CustodyControlForm);
