/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, formValueSelector } from 'redux-form';
import { camelize } from 'humps';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { Checkbox, ReCaptchaV2, Text } from './fields';
import Panel from './Panel';
import * as V from '../lib/validations';
import * as N from '../lib/normalizations';
import OverridableTranslate from './translate/OverrideableTranslate';

export class SignatureComponent extends Component {
  static propTypes = {
    finalPage: PropTypes.bool,
    hasReviewPage: PropTypes.bool,
    inputKey: PropTypes.string,
    dob: PropTypes.object,
    candidatesCanBeMinor: PropTypes.bool,
    i18nOverrides: PropTypes.object,
  };

  render() {
    const {
      finalPage,
      hasReviewPage,
      inputKey,
      dob,
      candidatesCanBeMinor,
      i18nOverrides,
    } = this.props;

    const signatureFieldName =
      inputKey && inputKey !== 'signature'
        ? camelize(`signature_${inputKey}`)
        : 'signature';

    const minorCutoff = new Date();
    minorCutoff.setFullYear(minorCutoff.getFullYear() - 18);
    minorCutoff.setHours(0, 0, 0, 0);
    const dobDate = dob
      ? new Date(Date.parse(`${dob.year}/${dob.month}/${dob.day}`))
      : new Date();
    dobDate.setHours(0, 0, 0, 0);

    return (
      <Panel
        title='components.Signature.title'
        icon='fa-pencil'
        key={signatureFieldName}
      >
        {candidatesCanBeMinor == null ||
        candidatesCanBeMinor === false ||
        dobDate < minorCutoff ? (
          <div>
            <p>
              <strong>
                <OverridableTranslate
                  value='components.Signature.header'
                  overrides={i18nOverrides}
                  dangerousHTML
                />
              </strong>
            </p>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  name={signatureFieldName}
                  label='labels.fullName'
                  component={Text}
                  validate={[V.required, V.signatureFormat]}
                  normalize={N.smartPunctuation}
                />
              </div>
              <div className='row'>
                {finalPage && !hasReviewPage && (
                  <div className='col-md-6'>
                    <Field
                      name='recaptchaCode'
                      component={ReCaptchaV2}
                      validate={V.required}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p>
              <strong>
                <T value='components.BGCMinorConsentSignature.header' />
              </strong>
            </p>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  name={signatureFieldName}
                  label='labels.fullName'
                  component={Text}
                  validate={[V.required, V.signatureFormat]}
                />
              </div>
            </div>
            <p>
              <strong>
                <T value='components.BGCMinorConsentSignature.guardianHeader' />
              </strong>
            </p>
            <div className='row'>
              <div className='col-md-6'>
                <Field
                  name='guardian_name'
                  label='labels.fullName'
                  component={Text}
                  validate={[V.required]}
                />
              </div>
              <div className='col-md-6'>
                <Field
                  name='guardian_email'
                  label='labels.email'
                  component={Text}
                  validate={[V.required, V.emailFormat]}
                />
              </div>
            </div>
            <div className='col-md-12'>
              <Field
                type='checkbox'
                name='guardian_acknowledgement'
                label='components.BGCMinorConsentSignature.guardianAcknowledgement'
                component={Checkbox}
                validate={V.required}
                boldLabel
              />
            </div>
            <div className='row'>
              {finalPage && !hasReviewPage && (
                <div className='col-md-6'>
                  <Field
                    name='recaptchaCode'
                    component={ReCaptchaV2}
                    validate={V.required}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Panel>
    );
  }
}

const selector = formValueSelector('applyform');
const ConnectedSignature = connect((state, props) => {
  // eslint-disable-next-line prefer-destructuring
  const pages = state.configuration.configuration.pages;
  const hasReviewPage = pages.some(page => page.name === 'reviewAndSubmit');
  return {
    dob: selector(state, 'dob'),
    hasReviewPage,
  };
})(SignatureComponent);

export default ConnectedSignature;
