/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { change, Field, formValueSelector } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import isDisabled from './helper';

import { requestConfigurationUpdate } from '../actions';

import Panel from './Panel';
import SsnFields from './SsnFields';

import { Checkbox, Select, Text } from './fields';

import * as N from '../lib/normalizations';
import * as O from '../lib/options';
import * as V from '../lib/validations';
import * as VM from '../lib/validations/messages';
import MotherMaidenNameField from './MotherMaidenNameField';

export class ApplicantInformationFormComponent extends Component {
  static propTypes = {
    candidate: PropTypes.object,
    change: PropTypes.func.isRequired,
    dob: PropTypes.bool.isRequired,
    maidenName: PropTypes.bool.isRequired,
    noMiddleName: PropTypes.bool,
    protectedInvitationFlow: PropTypes.bool,
    protectedInvitationFields: PropTypes.array,
    requestConfigurationUpdate: PropTypes.func.isRequired,
    ssn: PropTypes.bool.isRequired,
    noSsn: PropTypes.bool,
    candidatesCanDeclareNoSsn: PropTypes.bool.isRequired,
    zipcode: PropTypes.bool.isRequired,
    candidatesCanBeMinor: PropTypes.bool,
  };

  render() {
    const {
      ssn,
      noSsn,
      candidatesCanDeclareNoSsn,
      maidenName,
      noMiddleName,
      zipcode,
      // eslint-disable-next-line no-shadow
      change,
      // eslint-disable-next-line no-shadow
      requestConfigurationUpdate,
      protectedInvitationFlow,
      protectedInvitationFields,
      candidate,
      dob,
      candidatesCanBeMinor,
    } = this.props;

    // eslint-disable-next-line no-shadow
    const onZipcodeUpdate = ({ target: { value: zipcode } }) => {
      if (zipcode && zipcode.length === 5) {
        requestConfigurationUpdate({ params: { zipcode } });
      }
    };

    const onNoMiddleNameUpdate = ({ target: { checked } }) => {
      if (checked) {
        change('applyform', 'middleName', null);
      }
    };

    const shouldShowNoMiddleNameCheckbox =
      !isDisabled({
        field: candidate.middleName,
        protect:
          protectedInvitationFlow &&
          protectedInvitationFields.includes('middle_name'),
      }) && !candidate.noMiddleName;

    const onNoSsnUpdate = ({ target: { checked } }) => {
      if (checked) {
        change('applyform', 'ssn', null);
        change('applyform', 'ssnConfirmation', null);
      }
    };

    const middleNameIsDisabled =
      isDisabled({
        field: candidate.middleName,
        protect:
          protectedInvitationFlow &&
          protectedInvitationFields.includes('middle_name'),
      }) || noMiddleName;

    return (
      <Panel
        additionalText={`components.ApplicantInformationForm.${
          protectedInvitationFlow ? 'lockedAdditionalText' : 'additionalText'
        }`}
        title='components.ApplicantInformationForm.title'
        icon='fa-user'
      >
        <div className='row' data-testid='applicant-information-form'>
          <div className='col-md-4'>
            <Field
              type='text'
              name='firstName'
              label='labels.firstName'
              component={Text}
              validate={[V.required, V.nameFormat]}
              normalize={N.smartPunctuation}
              disabled={isDisabled({
                field: candidate.firstName,
                protect:
                  protectedInvitationFlow &&
                  protectedInvitationFields.includes('first_name'),
              })}
            />
          </div>

          <div className='col-md-4'>
            <Field
              type='text'
              name='middleName'
              label='labels.middleName'
              disabled={middleNameIsDisabled}
              labelAsRequired={false}
              component={Text}
              normalize={N.smartPunctuation}
              validate={[V.nameFormat]}
            />

            {shouldShowNoMiddleNameCheckbox && (
              <Field
                type='checkbox'
                name='noMiddleName'
                label='labels.noMiddleName'
                className='inline-checkbox top-inline bottom-inline middle-name'
                onChange={onNoMiddleNameUpdate}
                component={Checkbox}
                validate={(checked, { middleName }) =>
                  !middleName && !checked && VM.REQUIRED
                }
              />
            )}
          </div>

          <div className='col-md-4'>
            <Field
              type='text'
              name='lastName'
              label='labels.lastName'
              component={Text}
              normalize={N.smartPunctuation}
              validate={[V.required, V.nameFormat, V.lastNameFormat]}
              disabled={isDisabled({
                field: candidate.lastName,
                protect:
                  protectedInvitationFlow &&
                  protectedInvitationFields.includes('last_name'),
              })}
            />
          </div>
        </div>
        {dob && [
          <label key='dob-label'>
            <T value='labels.dob' />
          </label>,
          <div className='row' key='dob-row'>
            <div className='col-md-4'>
              <Field
                type='select'
                name='dob.month'
                component={Select}
                validate={[V.required]}
                options={O.months()}
                disabled={isDisabled({
                  field: candidate.dob?.month,
                  protect:
                    protectedInvitationFlow &&
                    protectedInvitationFields.includes('dob'),
                })}
              />
            </div>

            <div className='col-md-4'>
              <Field
                type='select'
                name='dob.day'
                component={Select}
                validate={[V.required]}
                options={O.days()}
                disabled={isDisabled({
                  field: candidate.dob?.day,
                  protect:
                    protectedInvitationFlow &&
                    protectedInvitationFields.includes('dob'),
                })}
              />
            </div>

            <div className='col-md-4'>
              <Field
                type='select'
                name='dob.year'
                component={Select}
                validate={[V.required]}
                options={
                  candidatesCanBeMinor === true
                    ? O.years(14, 110)
                    : O.years(18, 110)
                }
                disabled={isDisabled({
                  field: candidate.dob?.year,
                  protect:
                    protectedInvitationFlow &&
                    protectedInvitationFields.includes('dob'),
                })}
              />
            </div>
          </div>,
        ]}

        {ssn && (
          <SsnFields
            noSsn={noSsn}
            candidatesCanDeclareNoSsn={candidatesCanDeclareNoSsn}
            protectedInvitationFlow={protectedInvitationFlow}
            protectedInvitationFields={protectedInvitationFields}
            candidate={candidate}
            onNoSsnUpdate={onNoSsnUpdate}
          />
        )}

        <div className='row'>
          {zipcode && (
            <div className='col-md-6'>
              <Field
                type='text'
                name='zipcode'
                label='labels.currentZipcode'
                placeholder='20500'
                component={Text}
                normalize={N.zipcode}
                onBlur={onZipcodeUpdate}
                validate={[V.required, V.zipcodeFormat]}
                disabled={isDisabled({
                  field: candidate.zipcode,
                  protect:
                    protectedInvitationFlow &&
                    protectedInvitationFields.includes('zipcode'),
                })}
              />
            </div>
          )}
        </div>

        {maidenName && (
          <MotherMaidenNameField
            candidate={candidate}
            protectedInvitationFlow={protectedInvitationFlow}
            protectedInvitationFields={protectedInvitationFields}
          />
        )}
      </Panel>
    );
  }
}

const selector = formValueSelector('applyform');
const ConnectedApplicantInformationForm = connect(
  (state, props) => {
    const { candidate } = state.configuration.configuration;
    return {
      protectedInvitationFlow:
        state.configuration.configuration.package.protectedInvitationFlow,
      protectedInvitationFields:
        state.configuration.configuration.package.protectedInvitationFields,
      candidate,
      noMiddleName: selector(state, 'noMiddleName'),
      middleName: selector(state, 'middleName'),
      noSsn: selector(state, 'noSsn'),
    };
  },
  { change, requestConfigurationUpdate },
)(ApplicantInformationFormComponent);

export default ConnectedApplicantInformationForm;
