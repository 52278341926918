import React from 'react';
import PropTypes from 'prop-types';
import './Notes.scss';

const Notes = ({ title, text }) => {
  return (
    <div className='notes-container'>
      <div className='notes-inner'>
        {title && <div className='notes-title'>{title}</div>}
        {text && <div className='notes-text'>{text}</div>}
      </div>
    </div>
  );
};

Notes.propTypes = {
  title: PropTypes.string,
  text: PropTypes.any,
};

Notes.defaultProps = {
  title: '',
  text: '',
};

export default Notes;
