import { M, Download16 } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import React from 'react';
import { Field, FieldArray, Validator } from 'redux-form';

import ChoiceCard from 'components/ChoiceCard';
import ChoiceCardWithInstructions from 'components/ChoiceCardWithInstructions';
import {
  ReduxMDropdown,
  ReduxMInput,
  ReduxMCheckbox,
  ReduxMCombobox,
} from '../FieldComponents/WrappedMastodonComps';

import {
  MDropdownProps,
  MDropdownItems,
  MInputProps,
  MCheckboxProps,
  FieldToMastodonProps,
  MDatePickerProps,
} from '../FieldComponents/WrappedMastodonComps/WrappedComp.types';

import fieldArrayContentFactory from '../FieldComponents/FieldWrappers/FieldArrayWrapper';

import {
  RelevantFieldCreator,
  RenderableElementCreator,
  RelevantComplexFieldCreator,
} from './RelevantFieldCreator.class';

import { PassThroughPropsMap, RenderableSchemaProperty } from './Field.types';

import { requiredArray } from '../../../lib/validations';
import HTMLText from '../../../components/HTMLText';
import Upload from '../../../components/fields/Upload';
import Instructions from '../../Components/Instructions';
import InstructionsAndAccordion from '../../Components/InstructionsAndAccordion';
import DownloadAcknowledgement from '../../Components/DownloadAcknowledgement';
import IdentityDocument from '../../Components/IdentityDocument';
import ReduxDatePicker from '../FieldComponents/WrappedMastodonComps/DatePicker';
import Notes from '../../Components/Notes';

const generateDropdownField = ({
  id,
  title,
  placeholder,
  items,
  className,
}: FieldToMastodonProps & { items: MDropdownItems }): RelevantFieldCreator => {
  const props: MDropdownProps = {
    id,
    titleText: title,
    label: placeholder,
    items,
    className,
  };

  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, onChange, ...passThroughProps }) => (
      <Field
        key={id}
        name={id}
        component={ReduxMDropdown}
        validate={validate}
        onChange={onChange}
        props={{ ...props, ...passThroughProps }}
      />
    ),
  );
};

const generateComboboxField = ({
  id,
  title,
  placeholder,
  items,
}: FieldToMastodonProps & { items: MDropdownItems }): RelevantFieldCreator => {
  const props: MDropdownProps = {
    id,
    titleText: title,
    label: placeholder,
    items,
  };

  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => (
      <Field
        key={id}
        name={id}
        component={ReduxMCombobox}
        validate={validate}
        props={{ ...props, ...passThroughProps }}
      />
    ),
  );
};

const generateInputField = ({
  id,
  title,
  placeholder,
  className,
  value,
  format,
  parse,
}: FieldToMastodonProps & {
  format?: (value?: any) => string;
  parse?: (value?: string) => any;
}): RelevantFieldCreator => {
  const props: MInputProps = {
    id,
    labelText: title,
    placeholder,
    className,
    value,
  };
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, onChange, ...passThroughProps }) => {
      return (
        <Field
          key={id}
          name={id}
          component={ReduxMInput}
          validate={validate}
          onChange={onChange}
          format={format}
          parse={parse}
          props={{ ...props, ...passThroughProps }}
        />
      );
    },
  );
};

const generateUploadField = (
  {
    id,
    title,
    placeholder,
    stepData,
  }: FieldToMastodonProps & { stepData?: { index: number } },
  maxFiles = 1,
): RelevantFieldCreator => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => (
      <>
        {title && <div>{title}&nbsp;</div>}
        <div className='download-button-wrapper'>
          {stepData && (
            <M.Tag type='teal'>
              {i18n.getStr('international.labels.stepLabel', stepData.index)}
            </M.Tag>
          )}
          <Field
            key={id}
            type='upload'
            name={id}
            component={Upload}
            options={{ maxFiles: 1 }}
            validate={validate}
            boldLabel
            props={{
              v1: true,
              options: { maxFiles },
              international: true,
              ...passThroughProps,
            }}
          />
        </div>
      </>
    ),
  );
};

function generateArrayField<T>(
  id: string,
  idGenerator: (id: string) => RenderableElementCreator,
  showErrorNotification: boolean,
) {
  const wrappedRFC = fieldArrayContentFactory(
    id,
    idGenerator,
    showErrorNotification,
  );
  return RelevantFieldCreator.fromGenerator(id, passThroughProps => {
    const validate: Validator[] = [requiredArray].concat(
      passThroughProps.validate || [],
    );

    return (
      <FieldArray
        name={id}
        component={wrappedRFC}
        validate={id === 'address' ? validate : passThroughProps.validate}
      />
    );
  });
}

const generateDownloadField = ({
  id,
  title,
  filename,
  href,
  placeholder,
  stepData,
}: FieldToMastodonProps & {
  filename: string;
  href: string;
  stepData?: { index: number };
}) => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => (
      <>
        {title && <div className='download-header-text'>{title}</div>}
        <div className='download-button-wrapper'>
          {stepData && (
            <M.Tag type='teal' style={{ whiteSpace: 'noWrap' }}>
              {i18n.getStr('international.labels.stepLabel', stepData.index)}
            </M.Tag>
          )}
          <M.Button
            style={{ marginLeft: '.625rem' }}
            kind='secondary'
            href={href}
            download={filename}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Download16 />
            &nbsp;{i18n.getStr('i18nInternational.instructions.download')}
          </M.Button>
        </div>
      </>
    ),
  );
};

const generateCheckboxField = ({
  id,
  title,
  disabled,
}: FieldToMastodonProps): RelevantFieldCreator => {
  const props: MCheckboxProps = {
    id,
    labelText: title,
  };

  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, onClick, ...passThroughProps }) => {
      return (
        <Field
          key={id}
          name={id}
          component={ReduxMCheckbox}
          validate={validate}
          onChange={onClick}
          disabled={disabled}
          props={{
            ...props,
            onClick,
            ...passThroughProps,
          }}
        />
      );
    },
  );
};

const generateInstructionField = ({
  id,
  title,
  text,
  filename,
  href,
  isHtmlContent = false,
}: FieldToMastodonProps & {
  text: string;
  filename: string;
  href: string;
  isHtmlContent?: boolean;
}): RelevantFieldCreator => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => (
      <>
        <Instructions
          title={title}
          text={text}
          filename={filename}
          href={href}
          isHtmlContent={isHtmlContent}
        />
      </>
    ),
  );
};

const generateInstructionAndAccordionField = ({
  id,
  title,
  question,
  body,
  accordionTitle,
  accordionBody,
  isOpen,
}: FieldToMastodonProps & {
  body: string;
  question: string;
  accordionTitle: string;
  accordionBody: string;
  isOpen: boolean;
}): RelevantFieldCreator => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => (
      <>
        <InstructionsAndAccordion
          title={title}
          question={question}
          text={body}
          accordionTitle={accordionTitle}
          accordionBody={accordionBody}
          isOpen={isOpen}
        />
      </>
    ),
  );
};

const generateDownloadAcknowledgementField = ({
  id,
  title,
  text,
  acknowledgement,
  filename,
  href,
  className,
}: FieldToMastodonProps & {
  text: string;
  acknowledgement: any;
  filename: string;
  href: string;
}): RelevantFieldCreator => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => (
      <>
        <DownloadAcknowledgement
          title={title}
          text={text}
          acknowledgement={acknowledgement}
          filename={filename}
          href={href}
          className={className}
        />
      </>
    ),
  );
};

const generateIdentityDocumentField = ({
  arrayPathModifier,
  generateField,
  id,
  properties,
}: {
  arrayPathModifier?: string;
  generate: any;
  generateField: any;
  id: string;
  properties: any;
}): RelevantFieldCreator => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => {
      return (
        <IdentityDocument
          arrayPathModifier={arrayPathModifier}
          generateField={generateField}
          properties={properties}
        />
      );
    },
  );
};

const generateDatePicker = ({
  id,
  title,
  placeholder,
  isMonthYear,
  dateFormat,
}: FieldToMastodonProps & {
  isMonthYear?: boolean;
  dateFormat?: string;
}): RelevantFieldCreator => {
  const props: MDatePickerProps = {
    id,
    labelText: title,
    placeholder,
    isMonthYear,
    dateFormat,
  };
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, onChange, ...passThroughProps }) => {
      return (
        <Field
          key={id}
          name={id}
          component={ReduxDatePicker}
          validate={validate}
          onChange={onChange}
          props={{ ...props, ...passThroughProps }}
        />
      );
    },
  );
};
const FlexContainerWrapper = ({
  children,
  wrapInFlexContainer,
}: {
  children: React.ReactNode;
  wrapInFlexContainer?: boolean;
}) => (
  <>
    {wrapInFlexContainer ? (
      <div className='flex-container-button-group'>{children}</div>
    ) : (
      children
    )}
  </>
);

const generateDocumentField = ({
  label,
  field,
  fullFormId,
  passThroughComponent,
  wrapInFlexContainer,
}: FieldToMastodonProps & {
  label: string;
  field: RenderableSchemaProperty;
  fullFormId: string;
  passThroughComponent: RelevantFieldCreator[];
  wrapInFlexContainer?: boolean;
}): RelevantComplexFieldCreator => {
  if (field.extended_configuration) {
    const elemStyle = field.extended_configuration.component_style
      ? field.extended_configuration.component_style
      : 'transparent';

    return RelevantComplexFieldCreator.fromComplexGenerator(
      label,
      [fullFormId],
      props => [
        <div key='documemt-field-row'>
          {
            <M.Container
              type={elemStyle}
              rows={[
                field.extended_configuration?.title && (
                  <h2 key='document-title'>
                    {field.extended_configuration?.title}
                  </h2>
                ),
                field.extended_configuration?.body && (
                  <HTMLText
                    key='document-content'
                    content={field.extended_configuration?.body}
                  />
                ),
                passThroughComponent && passThroughComponent.length !== 0 && (
                  <FlexContainerWrapper
                    wrapInFlexContainer={wrapInFlexContainer}
                  >
                    {renderBtnGroup({
                      passThroughComponent,
                      props,
                      fullFormId,
                    })}
                  </FlexContainerWrapper>
                ),
              ]}
            />
          }
        </div>,
      ],
    );
  }

  return RelevantComplexFieldCreator.fromComplexGenerator(
    label,
    [fullFormId],
    props => [renderBtnGroup({ passThroughComponent, props, fullFormId })],
  );
};

const renderBtnGroup = ({
  passThroughComponent,
  props,
  fullFormId,
}: {
  passThroughComponent: RelevantFieldCreator[];
  props: PassThroughPropsMap;
  fullFormId: string;
}): JSX.Element => {
  return (
    <div
      key='custom-flex-row-1'
      className='custom-flex-row btn-group-container'
    >
      {passThroughComponent
        .map(btn => btn.render(props.get(fullFormId)))
        .map((col, j) => {
          const colKey = `custom-flex-row-1-col-${j}`;

          return (
            <>
              {fullFormId.includes('international_consent_form') ? (
                <div className='international-consent-form-button-container'>
                  {col}
                </div>
              ) : (
                <div
                  key={colKey}
                  className={`custom-flex-row ${fullFormId.includes(
                    'international_consent_form',
                  ) && 'button-with-title-container'}`}
                >
                  {col}
                </div>
              )}
            </>
          );
        })}
    </div>
  );
};

const generateChoiceCardField = ({ id, title, disabled }: any) => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, onClick, ...passThroughProps }) => {
      return (
        <ChoiceCard
          id={id}
          title={title}
          disabled={disabled}
          onChange={onClick}
          {...passThroughProps}
        />
      );
    },
  );
};

const generateChoiceCardWithInstructionsField = ({
  id,
  title,
  disabled,
}: any) => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, onClick, ...passThroughProps }) => {
      return (
        <ChoiceCardWithInstructions
          id={id}
          title={title}
          disabled={disabled}
          onChange={onClick}
          {...passThroughProps}
        />
      );
    },
  );
};

const generateNotesField = ({
  id,
  title,
  text,
}: FieldToMastodonProps & {
  text: string;
}): RelevantFieldCreator => {
  return RelevantFieldCreator.fromGenerator(
    id,
    ({ validate, ...passThroughProps }) => (
      <>
        <Notes title={title} text={text} />
      </>
    ),
  );
};

export default {
  arrayField: generateArrayField,
  dropdownField: generateDropdownField,
  checkboxField: generateCheckboxField,
  inputField: generateInputField,
  uploadField: generateUploadField,
  downloadField: generateDownloadField,
  comboboxField: generateComboboxField,
  instructionsField: generateInstructionField,
  instructionsAndAccordionField: generateInstructionAndAccordionField,
  downloadAcknowledgementField: generateDownloadAcknowledgementField,
  identityDocumentField: generateIdentityDocumentField,
  datePicker: generateDatePicker,
  documentField: generateDocumentField,
  choiceCardField: generateChoiceCardField,
  choiceCardWithInstructionsField: generateChoiceCardWithInstructionsField,
  notesField: generateNotesField,
};
